import './src/styles/global.css'
import '@fontsource/noto-sans-jp'
import '@fontsource/noto-sans-jp/300.css'
import '@fontsource/noto-sans-jp/500.css'
import '@fontsource/barlow'
import '@fontsource/barlow/500.css'
import React from 'react';

export const onClientEntry = () => {
  const script = document.createElement("script");
  script.src = "https://www.youtube.com/iframe_api";
  document.body.appendChild(script);
};


// export const wrapRootElement = ({ element }) => (
//   <>
//     {element}
//     <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" type="text/javascript" charSet="UTF-8" data-domain-script="e3560b7a-5bb0-4e9c-be55-c45e179d8328" />
//     <script type="text/javascript">
//       {`
//         function OptanonWrapper() { }
//       `}
//     </script>
//   </>
// );
